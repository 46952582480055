<template>
  <div class="warp">
    <van-form @submit="onSubmit">
      <div class="main">
        <h3>个人信息</h3>
        <van-radio-group v-model="formMess.runnerType" direction="horizontal">
          <span class="jffs">身份类型</span>
          <van-radio :name="1">清运员</van-radio>
          <van-radio :name="2">回收员</van-radio>
        </van-radio-group>
        <!-- 姓名 -->
        <van-field
                  v-model="formMess.trueName"
                  label="姓名"
                  placeholder="请输入姓名"
                  :rules="[{ required: true, message: '请输入正确的姓名' }]" />
        <!-- 手机号 -->
        <van-field
                  v-model="formMess.userMobile"
                  type="tel"
                  label="手机号"
                  placeholder="请输入手机号"
                  :rules="[{ pattern: /^(0|86|17951)?1[\d]{10}$/, message: '请输入正确的手机号' }]" />
        <!-- 身份证号 -->
        <van-field
                  v-model="formMess.runnerCard"
                  type="text"
                  label="身份证号"
                  placeholder="请输入身份证号"
                  :rules="[{ pattern: /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/, message: '请输入正确的身份证号' }]" />
        <!-- 身份证正面 -->
        <van-field name="cardFront" label="身份证正面">
          <template #input>
            <van-uploader
              v-model="cardFront"
              :max-count="1" />
          </template>
        </van-field>
        <!-- 身份证反面 -->
        <van-field name="cardBehind" label="身份证背面">
          <template #input>
            <van-uploader
              v-model="cardBehind"
              :max-count="1" />
          </template>
        </van-field>
        <!-- 道路运输许可证 -->
        <van-field name="roadCard" label="道路运输许可证">
          <template #input>
            <van-uploader
              v-model="roadCard"
              :max-count="1" />
          </template>
        </van-field>
        <!-- 驾驶证 -->
        <van-field name="driveCard" label="驾驶证">
          <template #input>
            <van-uploader
              v-model="driveCard"
              :max-count="1" />
          </template>
        </van-field>
        <!-- 行驶证 -->
        <van-field name="regCard" label="行驶证">
          <template #input>
            <van-uploader
              v-model="regCard"
              :max-count="1" />
          </template>
        </van-field>
        <!-- 车辆保险 -->
        <van-field name="carInsure" label="车辆保险">
          <template #input>
            <van-uploader
              v-model="carInsure"
              :max-count="1" />
          </template>
        </van-field>

        <h3>所在区域</h3>
        <!-- 省市县 -->
        <van-field readonly
                clickable
                name="provinceName"
                :value="provinceName"
                label="省级"
                placeholder="点击选择省级"
                @click="showPicker1"
                :rules="[{ required: true, message: '请选择省级' }]" />
      <van-field readonly
                clickable
                name="cityName"
                :value="cityName"
                label="市级"
                placeholder="点击选择市级"
                @click="showPicker2"
                :rules="[{ required: true, message: '请选择市级' }]" />
      <van-field readonly
                clickable
                name="districtName"
                :value="districtName"
                label="县级"
                placeholder="点击选择县级"
                @click="showPicker3"
                :rules="[{ required: true, message: '请选择县级' }]" />
      <van-field readonly
                clickable
                name="townName"
                :value="townName"
                label="乡镇"
                placeholder="点击选择乡镇"
                @click="showPicker4"
                :rules="[{ required: true, message: '请选择乡镇' }]" />
      <van-field readonly
                clickable
                name="villageName"
                :value="villageName"
                label="社区(村)"
                placeholder="点击选择社区(村)"
                @click="showPicker5"
                :rules="[{ required: true, message: '请选择社区(村)' }]" />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
       
      </div>
      <div class="btn">
        <van-button v-if="isShowbtn" round block type="info" native-type="submit">提交</van-button>
        <van-button v-else loading  type="primary" disabled block loading-text="请稍等..." />
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { runnerregister, getDivisionList, jsapisignature, getInfo } from '@/api/notice.js'
export default {
  data () {
    return {
      client: new OSS.Wrapper({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI5tRypK9PNyfDKPcabDhD',
        accessKeySecret: 'ykKGkcSM0ewsUcW4rAUd9htNOJwKVp',
        bucket: 'hzjiaduomei-huishou'
      }),
      isShowbtn: true,
      cardFront: undefined, // 身份证正面
      cardBehind: undefined, // 身份证反面
      roadCard: undefined, // 道路运输许可证
      driveCard: undefined, // 驾驶证
      regCard: undefined, // 行驶证
      carInsure: undefined, // 车辆保险
      query: {
        level: undefined,
        divisionId: undefined
      },
      columns: [],
      showPicker: false,
      editId: undefined, // 保存编辑跳转的id
      provinceId: undefined, // 保存县级id
      cityId: undefined, // 保存市级id
      districtId: undefined, // 保存区级id
      townId: undefined, // 保存镇级id
      provinceName: undefined, // 省份
      cityName: undefined, // 市
      districtName: undefined, // 地区
      townName: undefined, // 街道城镇
      villageName: undefined, // 乡村社区
      formMess: {
        runnerType: undefined, // 身份 1:清运员 2:回收员
        trueName: undefined, // 真实姓名
        userMobile: undefined, // 手机号
        runnerCard: undefined, // 身份证号
        cardFront: undefined, // 身份证正面
        cardBehind: undefined, // 身份证反面
        roadCard: undefined, // 道路运输许可证
        driveCard: undefined, // 驾驶证
        regCard: undefined, // 行驶证
        carInsure: undefined, // 车辆保险
        divisionId: undefined, // 行政区域代码
      },
      tempArr: [], // 保存上传的图片
      urlArr: [], // 保存上传的图片url
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      getInfo().then(res => {
        if (res.data.code === 200 && res.data.data.hsRunner) {
          const isPass = res.data.data.hsRunner.isPass
          if (isPass === 1) {
            this.$router.push({path: 'Errorpage', query: {msg: '对不起，您已经是清运员了，无需重复申请哦'}})
          } else if (isPass === 2) {
            Toast('您的审核未通过，请重新申请')
          } else if (isPass === 0) {
            this.$router.push({path: 'Errorpage', query: {msg: '您的申请正在审核中'}})
          }
        }
      })
    },
    showPicker1() { // 省
      this.query.level = 1
      getDivisionList(this.query).then(res => {
        const columnss = []
        columnss.push(res.data.data.divisions.find(item => item.divisionId == '33'))
        this.columns = columnss.map(item => {
          return {
            text: item.name,
            id: item.divisionId
          }
        })
        this.showPicker = true
      })
    },
    showPicker2() { // 市
      if (this.provinceName) {
        this.query.level = 2
        this.query.divisionId = this.provinceId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            return {
              text: item.name,
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker3() { // 县
      if (this.cityName) {
        this.query.level = 3
        this.query.divisionId = this.cityId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            return {
              text: item.name,
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker4() { //镇
      if (this.districtName) {
        this.query.level = 4
        this.query.divisionId = this.districtId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            return {
              text: item.name.replace('办事处', ''),
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    showPicker5() { // 村
      if (this.townName) {
        this.query.level = 5
        this.query.divisionId = this.townId
        getDivisionList(this.query).then(res => {
          this.columns = res.data.data.divisions.map(item => {
            item.name = item.name.replace(/村民委员会$/, '')
            item.name = item.name.replace(/村委会村委会$/, '村')
            item.name = item.name.replace(/居?委会$/, '')
            item.name = item.name.replace(/村村$/, '村')
            return {
              text: item.name,
              id: item.divisionId
            }
          })
          this.showPicker = true
        })
      }
    },
    onConfirm(value) { // 弹框
      if (this.query.level == 1) {
        this.provinceName = value.text
        this.cityName = ''
        this.districtName = ''
        this.townName = ''
        this.villageName = ''
        this.provinceId = value.id
      } else if (this.query.level == 2) {
        this.cityName = value.text
        this.districtName = ''
        this.townName = ''
        this.villageName = ''
        this.cityId = value.id
      } else if (this.query.level == 3) {
        this.districtName = value.text
        this.townName = ''
        this.villageName = ''
        this.districtId = value.id
      } else if (this.query.level == 4) {
        this.townName = value.text
        this.villageName = ''
        this.townId = value.id
      } else if (this.query.level == 5) {
        this.villageName = value.text
        this.formMess.divisionId = value.id
      }
      this.showPicker = false;
    },
    jsapisignature() {
      let hrefurl = sessionStorage.getItem("url")
      jsapiSignature({url: hrefurl}).then(res => {
        setTimeout(function () {
          wx.config({
            debug: false,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ['closeWindow']
          })
          wx.ready(function () {
            wx.closeWindow()
          })
        }, 2000)
      })
    },
    uploadDocuments(tempArr, i) { // 上传证件
      const Name = tempArr.name // 这是file里的name
      const suffix = Name.substr(Name.indexOf('.')) // 文件.后的后缀
      // 文件名:图片名字加上时间戳和9999以内随机数，防止重名
      const filename =`${i+1 + 'gl'}gygf/${new Date().getTime()}${Math.floor(Math.random() * 10000)}${suffix}`
      this.client.multipartUpload(filename, tempArr).then(res => { // 上传
        console.log('上传成功：', res)
        this.urlArr.push(res.name)
        if (this.urlArr.length === 6) {
          this.formMess.cardFront = this.urlArr.filter(item => item.includes('1gl'))[0]
          this.formMess.cardBehind = this.urlArr.filter(item => item.includes('2gl'))[0]
          this.formMess.roadCard = this.urlArr.filter(item => item.includes('3gl'))[0]
          this.formMess.driveCard = this.urlArr.filter(item => item.includes('4gl'))[0]
          this.formMess.regCard = this.urlArr.filter(item => item.includes('5gl'))[0]
          this.formMess.carInsure = this.urlArr.filter(item => item.includes('6gl'))[0]
          this.runnerregister() // 清运员申请
        }
      }).catch(err => {
        console.log('上传失败：', err)
      })
    },
    runnerregister () { //  清运员申请
      runnerregister(this.formMess).then(res => {
        if (res.data.code === 200) {
          Toast('信息提交成功请等待审核')
          this.jsapisignature() // 关闭窗口
        }
      })
    },
    onSubmit (file) { // 提交按钮
      if (this.cardBehind && this.cardFront && this.roadCard && this.driveCard && this.regCard && this.carInsure) {
        this.isShowbtn = false
        let arr1 = file.cardFront[0].file
        let arr2 = file.cardBehind[0].file
        let arr3 = file.roadCard[0].file
        let arr4 = file.driveCard[0].file
        let arr5 = file.regCard[0].file
        let arr6 = file.carInsure[0].file
        this.tempArr.push(arr1, arr2, arr3, arr4, arr5, arr6)
        for (let i = 0; i < this.tempArr.length; i++) {
          this.uploadDocuments(this.tempArr[i], i)
        }
      } else {
        Toast('请上传证件照片')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  .main {
    padding: 15px;
    background-color: #fff;
    .jffs {
      margin-right: 35px;
    }
  }
}
::v-deep .van-radio-group--horizontal {
  align-items: center;
  height: 44px;
  padding: 10px 15px;
  margin-top: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-uploader__upload {
  width: 70px;
  height: 70px;
}
::v-deep .van-uploader__preview-image {
  width: 70px;
  height: 70px;
}
</style>
